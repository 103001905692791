import React, { useState } from 'react';

const DifficultySelector = ({ setDifficulty }) => {
  const [selectedDifficulty, setSelectedDifficulty] = useState('easy');



  const handleChange = (event) => {
    const difficulty = event.target.value;
    setSelectedDifficulty(difficulty);
    setDifficulty(difficulty);
  };

  return (
    <div>
      <label>Select Difficulty: </label>
      <select value={selectedDifficulty} onChange={handleChange}>
        <option value="easy">Easy (2000-2024)</option>
        <option value="medium">Medium (1985-1999)</option>
        <option value="hard">Hard (1930-1984)</option>
      </select>
    </div>
  );
};

export default DifficultySelector;
