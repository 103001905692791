// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyB_-ZnfYH_D8LKC2sIgT767Jw_GXLJP25Q",
  authDomain: "firebase-adminsdk-yyuo0@moviewordle.iam.gserviceaccount.com",
  projectId: "moviewordle",
  storageBucket: "default",
  messagingSenderId: "228922677662",
  appId: "MoviePosterApp"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);
