// import movies from '../data/movies.json';

// const getMovieOfTheDay = (difficulty) => {
//   const storageKey = `movie-of-the-day-${difficulty}`;
//   const lastMovieData = JSON.parse(localStorage.getItem(storageKey));
//   const now = new Date();

//   if (lastMovieData && new Date(lastMovieData.date).getDate() === now.getDate()) {
//     return lastMovieData.movie;
//   }

//   const movieList = movies[difficulty];
//   const randomIndex = Math.floor(Math.random() * movieList.length);
//   const movieOfTheDay = movieList[randomIndex];

//   localStorage.setItem(storageKey, JSON.stringify({ date: now, movie: movieOfTheDay }));
  
//   return movieOfTheDay;
// };

// export default getMovieOfTheDay;

import movieList from '../data/movies.json'; // Ensure you have a list of movies


const getMovieOfTheDay = (difficulty, seed) => {
  const filteredMovies = movieList.filter(movie => {
    if (difficulty === 'easy') return movie.year >= 2000 && movie.year <= 2024;
    if (difficulty === 'medium') return movie.year >= 1985 && movie.year <= 1999;
    if (difficulty === 'hard') return movie.year >= 1930 && movie.year <= 1984;
  });

  // Use the seed to get a consistent movie
  const index = seed % filteredMovies.length;
  return filteredMovies[index];
};

export default getMovieOfTheDay;

