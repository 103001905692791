// import React, { useState, useEffect } from 'react';
// import './App.css';
// import MovieImage from './MovieImage';
// import GuessInput from './GuessInput';
// import Auth from './Auth';
// import { auth, firestore } from './firebase';
// import { onAuthStateChanged } from 'firebase/auth';
// import { doc, getDoc, setDoc } from 'firebase/firestore';

// const years = [1995, 2000, 2005, 2010, 2015, 2020]; // Example list of years
// const moviesByYear = {
//   1995: ['Toy Story', 'Se7en', 'Braveheart'],
//   2000: ['Gladiator', 'Memento', 'X-Men'],
//   2005: ['Batman Begins', 'Harry Potter and the Goblet of Fire', 'Sin City'],
//   2010: ['Inception', 'The Social Network', 'Black Swan'],
//   2015: ['Mad Max: Fury Road', 'The Martian', 'Inside Out'],
//   2020: ['Tenet', 'Dolittle', 'Sonic the Hedgehog']
// };

// const App = () => {
//   const [movieTitle, setMovieTitle] = useState('');
//   const [moviePoster, setMoviePoster] = useState('');
//   const [movieYear, setMovieYear] = useState('');
//   const [movieGenre, setMovieGenre] = useState('');
//   const [leadActor, setLeadActor] = useState('');
//   const [guesses, setGuesses] = useState(0);
//   const [correct, setCorrect] = useState(false);
//   const [user, setUser] = useState(null);
//   const [history, setHistory] = useState([]);
//   const [score, setScore] = useState(0);

//   const apiKey = '315d74c3'; // Replace with your OMDB API key

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, user => {
//       setUser(user);
//       if (user) {
//         fetchUserData(user);
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   const fetchUserData = async (user) => {
//     const userDocRef = doc(firestore, 'users', user.uid);
//     const userDoc = await getDoc(userDocRef);
//     if (userDoc.exists()) {
//       const data = userDoc.data();
//       setHistory(data.history || []);
//       setScore(data.score || 0);
//     }
//   };

//   useEffect(() => {
//     if (!user) return;
//     const selectRandomMovie = () => {
//       const randomYear = years[Math.floor(Math.random() * years.length)];
//       const movies = moviesByYear[randomYear];
//       const randomMovie = movies[Math.floor(Math.random() * movies.length)];
//       return { title: randomMovie, year: randomYear };
//     };

//     const fetchMovieData = async () => {
//       const { title, year } = selectRandomMovie();
//       setMovieYear(year);

//       try {
//         const response = await fetch(`https://www.omdbapi.com/?t=${title}&apikey=${apiKey}`);
//         const data = await response.json();
//         if (data.Poster) {
//           setMoviePoster(data.Poster);
//         } else {
//           console.error('No poster found for the movie');
//         }
//         if (data.Year) {
//           setMovieYear(data.Year);
//         }
//         if (data.Genre) {
//           setMovieGenre(data.Genre);
//         }
//         if (data.Actors) {
//           setLeadActor(data.Actors.split(', ')[0]); // Take the first actor as the lead actor
//         }
//         setMovieTitle(title);
//       } catch (error) {
//         console.error('Error fetching the movie data:', error);
//       }
//     };

//     fetchMovieData();
//   }, [apiKey, user]);

//   const normalizeString = (str) => {
//     return str.toLowerCase().replace(/[\s\W]+/g, '').replace(/^the/, '');
//   };

//   const handleGuess = (guess) => {
//     setGuesses(guesses + 1);
//     if (normalizeString(guess) === normalizeString(movieTitle)) {
//       setCorrect(true);
//       updateScoreAndHistory();
//     }
//   };

//   const updateScoreAndHistory = async () => {
//     const newScore = score + 1;
//     const newHistory = [...history, { title: movieTitle, correct: true }];

//     setScore(newScore);
//     setHistory(newHistory);

//     if (user) {
//       const userDocRef = doc(firestore, 'users', user.uid);
//       await setDoc(userDocRef, {
//         score: newScore,
//         history: newHistory
//       }, { merge: true });
//     }
//   };

//   if (!user) {
//     return <Auth setUser={setUser} />;
//   }

//   return (
//     <div className="App">
//       <h1>Guess the Movie</h1>
//       <MovieImage moviePoster={moviePoster} guesses={guesses} correct={correct} />
//       <GuessInput handleGuess={handleGuess} />
//       {correct && <p>Congratulations! You guessed it right!</p>}
//       {guesses >= 1 && <p>Hint: Year of release - {movieYear}</p>}
//       {guesses >= 3 && <p>Hint: Genre - {movieGenre}</p>}
//       {guesses >= 5 && <p>Hint: Lead actor - {leadActor}</p>}
//       <div>
//         <h2>Your Score: {score}</h2>
//         <h3>Guess History:</h3>
//         <ol>
//           {history.map((item, index) => (
//             <li key={index}>{item.title} - {item.correct ? 'Correct' : 'Incorrect'}</li>
//           ))}
//         </ol>
//       </div>
//     </div>
//   );
// };

// export default App;

//--------------------------------------------------------------------------------------------------------------------------------------------------------

// import React, { useState, useEffect } from 'react';
// import './App.css';
// import MovieImage from './MovieImage';
// import GuessInput from './GuessInput';
// import Auth from './Auth';
// import { auth, firestore } from './firebase';
// import { onAuthStateChanged, signOut } from 'firebase/auth';
// import { doc, getDoc, setDoc } from 'firebase/firestore';

// const App = () => {
//   const [movieTitle, setMovieTitle] = useState('');
//   const [moviePoster, setMoviePoster] = useState('');
//   const [movieYear, setMovieYear] = useState('');
//   const [leadActor, setLeadActor] = useState('');
//   const [plot, setPlot] = useState('');
//   const [genre, setGenre] = useState('');
//   const [guesses, setGuesses] = useState([]);
//   const [correct, setCorrect] = useState(false);
//   const [user, setUser] = useState(null);
//   const [history, setHistory] = useState([]);
//   const [score, setScore] = useState(0);
//   // const [error, setError] = useState('');

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, user => {
//       setUser(user);
//       if (user) {
//         fetchUserData(user);
//         fetchMovieData();
//       }
//     });

//     return () => unsubscribe();
//   },[]);


//   const fetchUserData = async (user) => {
//         const userDocRef = doc(firestore, 'users', user.uid);
//         const userDoc = await getDoc(userDocRef);
//         if (userDoc.exists()) {
//           const data = userDoc.data();
//           setHistory(data.history || []);
//           setScore(data.score || 0);
//         }
//       };

//   // const fetchUserData = async (user) => {
//   //   try {
//   //     const userDocRef = doc(firestore, 'users', user.uid);
//   //     const userDoc = await getDoc(userDocRef);
//   //     if (userDoc.exists()) {
//   //       const data = userDoc.data();
//   //       setHistory(data.history || []);
//   //       setScore(data.score || 0);
//   //     } else {
//   //       console.warn('No such document!');
//   //     }
//   //   } catch (error) {
//   //     console.error('Error fetching user data:', error.message);
//   //     setError('Account created! Email sent, please verify Email before Logging in');
//   //   }
//   // };


//   const fetchMovieData = async () => {
//     // Fetch movie data from movies.json
//     try {
//       const response = await fetch('/movies.json');
//       const data = await response.json();
//       const randomMovie = data[Math.floor(Math.random() * data.length)];
//       setMovieTitle(randomMovie.title);
//       setMovieYear(randomMovie.year);
//       fetchMovieDetails(randomMovie.title);
//     } catch (error) {
//       console.error('Error fetching movie data:', error);
//     }
//   };

//   const fetchMovieDetails = async (title) => {
//     try {
//       const response = await fetch(`https://www.omdbapi.com/?t=${encodeURIComponent(title)}&apikey=315d74c3`);
//       const data = await response.json();
//       if (data.Poster) {
//         setMoviePoster(data.Poster);
//       } else {
//         console.error('No poster found for the movie');
//       }
//       setLeadActor(data.Actors);
//       setPlot(data.Plot);
//       setGenre(data.Genre);
//     } catch (error) {
//       console.error('Error fetching movie details:', error);
//     }
//   };

//   const handleGuess = (guess) => {
//     const normalizedGuess = normalizeString(guess);
//     setGuesses([...guesses, normalizedGuess]);
//     console.log(`Guess Number: ${guesses.length}`);
//     if (guesses.length >= 8) {
//       setCorrect(false);
//       updateScoreAndHistoryIncorrect();
//     }else if (normalizedGuess === normalizeString(movieTitle)) {
//       setCorrect(true);
//       updateScoreAndHistoryCorrect();
//     }else{
//       setCorrect(false);
//     }
//   };

//     const updateScoreAndHistoryCorrect = async () => {
//     const newScore = score + 1;
//     const newHistory = [...history, { title: movieTitle, correct: true }];

//     setScore(newScore);
//     setHistory(newHistory);

//     if (user) {
//       const userDocRef = doc(firestore, 'users', user.uid);
//       await setDoc(userDocRef, {
//         score: newScore,
//         history: newHistory
//       }, { merge: true });
//     }
//   };


//   const updateScoreAndHistoryIncorrect = async () => {
//     const newScore = score;
//     const newHistory = [...history, { title: movieTitle, correct: false }];

//     setScore(newScore);
//     setHistory(newHistory);

//     if (user) {
//       const userDocRef = doc(firestore, 'users', user.uid);
//       await setDoc(userDocRef, {
//         score: newScore,
//         history: newHistory
//       }, { merge: true });
//     }
//   };



//   const handleLogout = async () => {
//     try {
//       await signOut(auth);
//     } catch (error) {
//       console.error('Error signing out:', error);
//     }
//   };

//   const normalizeString = (str) => {
//     return str.toLowerCase().replace(/[\s\W]+/g, '').replace(/^the/, '');
//   };

//   const nextMovie = () => {
//     setGuesses([]);
//     setCorrect(false);
//     fetchMovieData();
//   };

//   if (!user) {
//     return <Auth setUser={setUser} />;
//   }

//   if (correct) {
//     return (
//       <div className="App">
//         <h1>Guess the Movie</h1>
//         <button onClick={handleLogout}>Logout</button>
//         <MovieImage moviePoster={moviePoster} guesses={guesses.length} correct={correct} />
//         <h2>Congratulations! You guessed it right!</h2>
//         <p>The movie title was: {movieTitle}</p>
//         <p>Genre: {genre}</p>
//         <p>Lead Actor: {leadActor}</p>
//         <p>Plot: {plot}</p>
//         <p>Your guesses: {guesses.join(', ')}</p>
//         <h2>Your Total Score: {score}</h2>
//         <button onClick={nextMovie}>Next</button>
//       </div>
//     );
//   }

//   if (guesses.length >= 8) {
//     return (
//       <div className="App">
//         <h1>Guess the Movie</h1>
//         <button onClick={handleLogout}>Logout</button>
//         <MovieImage moviePoster={moviePoster} guesses={guesses.length} correct={correct} />
//         <h2>Sorry, you've run out of guesses!</h2>
//         <p>The movie title was: {movieTitle}</p>
//         <p>Year: {movieYear}</p>
//         <p>Genre: {genre}</p>
//         <p>Lead Actor: {leadActor}</p>
//         <p>Plot: {plot}</p>
//         <p>Your guesses: {guesses.join(', ')}</p>
//         <h2>Your Total Score: {score}</h2>
//         <button onClick={nextMovie}>Next</button>
//       </div>
//     );
//   }

//   let hint = null;
//   if (guesses.length >= 2) {
//     hint = <p>Year: {movieYear}</p>;
//   }
//   if (guesses.length >= 4) {
//     hint = <><p>Year: {movieYear}</p><p>Genre: {genre}</p></>;
//   }
//   if (guesses.length >= 6) {
//     hint = <><p>Year: {movieYear}</p><p>Genre: {genre}</p><p>Lead Actor: {leadActor}</p></>;
//   }
//   if (guesses.length >= 7) {
//     hint = <><p>Year: {movieYear}</p><p>Genre: {genre}</p> <p>Lead Actor: {leadActor}</p><p>Plot: {plot}</p></>;
//   }

//   return (
//     <div className="App">
//       <h1>Guess the Movie</h1>
//       <button onClick={handleLogout}>Logout</button>
//       <MovieImage moviePoster={moviePoster} guesses={guesses.length} correct={correct} />
//       {hint}
//       <GuessInput handleGuess={handleGuess} />
//       {/* Display movie title */}
//       <p>Title: {movieTitle}</p>
//       {/* Display guesses */}
//       <p>Your guesses: {guesses.join(', ')}</p>
//       <h2>Your Total Score: {score}</h2>
//     </div>
//   );
// };

// // return (
// //   <div className="App">
// //       {error && (
// //         <div className="error-popup">
// //           <p>{error}</p>
// //           <button onClick={() => setError('')}>Close</button>
// //         </div>
// //       )}
// //       <Auth setUser={setUser} />
    
// //     <h1>Guess the Movie</h1>
// //     <button onClick={handleLogout}>Logout</button>
// //     <MovieImage moviePoster={moviePoster} guesses={guesses.length} correct={correct} />
// //     {hint}
// //     <GuessInput handleGuess={handleGuess} />
// //     {/* Display movie title */}
// //     <p>Title: {movieTitle}</p>
// //     {/* Display guesses */}
// //     <p>Your guesses: {guesses.join(', ')}</p>
// //     <h2>Your Total Score: {score}</h2>
  
// //   </div>
// // );
// // };

// export default App;

//Working Below========================================================================================================================

// import React, { useState, useEffect } from 'react';
// import './App.css';
// import MovieImage from './MovieImage';
// import GuessInput from './GuessInput';
// import Auth from './Auth';
// import { auth, firestore } from './firebase';
// import { onAuthStateChanged, signOut } from 'firebase/auth';
// import { doc, getDoc, setDoc } from 'firebase/firestore';
// import getMovieOfTheDay from './services/movieService';
// import DifficultySelector from './components/DifficultySelector';

// const App = () => {
//   const [movieTitle, setMovieTitle] = useState('');
//   const [moviePoster, setMoviePoster] = useState('');
//   const [movieYear, setMovieYear] = useState('');
//   const [leadActor, setLeadActor] = useState('');
//   const [plot, setPlot] = useState('');
//   const [genre, setGenre] = useState('');
//   const [guesses, setGuesses] = useState([]);
//   const [correct, setCorrect] = useState(false);
//   const [user, setUser] = useState(null);
//   const [history, setHistory] = useState([]);
//   const [score, setScore] = useState(0);
//   const [difficulty, setDifficulty] = useState('easy');
//   const [completedDifficulties, setCompletedDifficulties] = useState({
//     easy: false,
//     medium: false,
//     hard: false
//   });
//   const [gameState, setGameState] = useState({
//     easy: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
//     medium: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
//     hard: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false }
//   });

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, user => {
//       setUser(user);
//       if (user) {
//         fetchUserData(user);
//       } else {
//         resetState();
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   useEffect(() => {
//     if (!user) return;
//     loadGameState(difficulty);
//   }, [difficulty, user]);

//   const resetState = () => {
//     setMovieTitle('');
//     setMoviePoster('');
//     setMovieYear('');
//     setLeadActor('');
//     setPlot('');
//     setGenre('');
//     setGuesses([]);
//     setCorrect(false);
//     setHistory([]);
//     setScore(0);
//     setDifficulty('easy');
//     setCompletedDifficulties({
//       easy: false,
//       medium: false,
//       hard: false
//     });
//     setGameState({
//       easy: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
//       medium: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
//       hard: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false }
//     });
//   };

//   const loadGameState = (difficulty) => {
//     const savedState = gameState[difficulty];
//     setMovieTitle(savedState.movieTitle);
//     setMoviePoster(savedState.moviePoster);
//     setMovieYear(savedState.movieYear);
//     setLeadActor(savedState.leadActor);
//     setPlot(savedState.plot);
//     setGenre(savedState.genre);
//     setGuesses(savedState.guesses);
//     setCorrect(savedState.correct);

//     if (!savedState.movieTitle) {
//       resetGame();
//     }
//   };

//   const resetGame = () => {
//     const movie = getMovieOfTheDay(difficulty);
//     setMovieTitle(movie.title);
//     setMovieYear(movie.year);
//     fetchMovieDetails(movie.title);
//     setGuesses([]);
//     setCorrect(false);
//     setGameState({
//       ...gameState,
//       [difficulty]: {
//         movieTitle: movie.title,
//         moviePoster: '',
//         movieYear: movie.year,
//         leadActor: '',
//         plot: '',
//         genre: '',
//         guesses: [],
//         correct: false,
//         completed: false
//       }
//     });
//   };

//   const fetchUserData = async (user) => {
//     const userDocRef = doc(firestore, 'users', user.uid);
//     const userDoc = await getDoc(userDocRef);
//     if (userDoc.exists()) {
//       const data = userDoc.data();
//       setHistory(data.history || []);
//       setScore(data.score || 0);
//       setCompletedDifficulties(data.completedDifficulties || {
//         easy: false,
//         medium: false,
//         hard: false
//       });
//       setGameState(data.gameState || {
//         easy: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
//         medium: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
//         hard: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false }
//       });
//     }
//   };

//   const fetchMovieDetails = async (title) => {
//     try {
//       const response = await fetch(`https://www.omdbapi.com/?t=${encodeURIComponent(title)}&apikey=315d74c3`);
//       const data = await response.json();
//       if (data.Poster) {
//         setMoviePoster(data.Poster);
//       } else {
//         console.error('No poster found for the movie');
//       }
//       setLeadActor(data.Actors);
//       setPlot(data.Plot);
//       setGenre(data.Genre);

//       setGameState({
//         ...gameState,
//         [difficulty]: {
//           ...gameState[difficulty],
//           moviePoster: data.Poster,
//           leadActor: data.Actors,
//           plot: data.Plot,
//           genre: data.Genre
//         }
//       });
//     } catch (error) {
//       console.error('Error fetching movie details:', error);
//     }
//   };

//   const handleGuess = (guess) => {
//     if (completedDifficulties[difficulty]) return; // Prevent guessing if completed

//     const normalizedGuess = normalizeString(guess);
//     const newGuesses = [...guesses, normalizedGuess];
//     setGuesses(newGuesses);

//     if (newGuesses.length >= 8) {
//       setCorrect(false);
//       updateScoreAndHistory(false);
//     } else if (normalizedGuess === normalizeString(movieTitle)) {
//       setCorrect(true);
//       updateScoreAndHistory(true);
//     }

//     setGameState({
//       ...gameState,
//       [difficulty]: {
//         ...gameState[difficulty],
//         guesses: newGuesses
//       }
//     });
//   };

//   const updateScoreAndHistory = async (isCorrect) => {
//     const newScore = isCorrect ? score + 1 : score;
//     const newHistory = [...history, { title: movieTitle, correct: isCorrect }];

//     setScore(newScore);
//     setHistory(newHistory);

//     setCompletedDifficulties({
//       ...completedDifficulties,
//       [difficulty]: true
//     });

//     const updatedGameState = {
//       ...gameState,
//       [difficulty]: {
//         ...gameState[difficulty],
//         correct: isCorrect,
//         completed: true
//       }
//     };
//     setGameState(updatedGameState);

//     if (user) {
//       const userDocRef = doc(firestore, 'users', user.uid);
//       await setDoc(userDocRef, {
//         score: newScore,
//         history: newHistory,
//         completedDifficulties: {
//           ...completedDifficulties,
//           [difficulty]: true
//         },
//         gameState: updatedGameState
//       }, { merge: true });
//     }
//   };

//   const handleLogout = async () => {
//     try {
//       await signOut(auth);
//       resetState(); // Clear state on logout
//     } catch (error) {
//       console.error('Error signing out:', error);
//     }
//   };

//   const normalizeString = (str) => {
//     return str.toLowerCase().replace(/[\s\W]+/g, '').replace(/^the/, '');
//   };

//   const nextMovie = () => {
//     resetGame();
//   };

//   if (!user) {
//     return <Auth setUser={setUser} />;
//   }

//   if (correct || completedDifficulties[difficulty]) {
//     return (
//       <div className="App">
//         <h1>Guess the Movie</h1>
//         <DifficultySelector setDifficulty={setDifficulty} />
//         <MovieImage moviePoster={moviePoster} guesses={guesses.length} correct={correct} />
//         <h2>{correct ? 'Congratulations! You guessed it right!' : 'This difficulty has already been completed for the day.'}</h2>
//         <p>The movie title was: {movieTitle}</p>
//         <p>Genre: {genre}</p>
//         <p>Lead Actor: {leadActor}</p>
//         <p>Plot: {plot}</p>
//         <p>Your guesses: {guesses.join(', ')}</p>
//         <h2>Your Total Score: {score}</h2>
//         {completedDifficulties[difficulty] && <p>You've completed this difficulty!</p>}
//         <button onClick={handleLogout}>Logout</button>
//       </div>
//     );
//   }

//   if (guesses.length >= 8 && !completedDifficulties[difficulty]) {
//     return (
//       <div className="App">
//         <h1>Guess the Movie</h1>
//         <DifficultySelector setDifficulty={setDifficulty} />
//         <MovieImage moviePoster={moviePoster} guesses={guesses.length} correct={correct} />
//         <h2>Sorry, you've run out of guesses!</h2>
//         <p>The movie title was: {movieTitle}</p>
//         <p>Year: {movieYear}</p>
//         <p>Genre: {genre}</p>
//         <p>Lead Actor: {leadActor}</p>
//         <p>Plot: {plot}</p>
//         <p>Your guesses: {guesses.join(', ')}</p>
//         <h2>Your Total Score: {score}</h2>
//         {completedDifficulties[difficulty] && <p>This difficulty has already been completed for the day.</p>}
//         <button onClick={handleLogout}>Logout</button>
//       </div>
//     );
//   }

//   let hint = null;
//   if (guesses.length >= 2) {
//     hint = <p>Year: {movieYear}</p>;
//   }
//   if (guesses.length >= 4) {
//     hint = <><p>Year: {movieYear}</p><p>Genre: {genre}</p></>;
//   }
//   if (guesses.length >= 6) {
//     hint = <><p>Year: {movieYear}</p><p>Genre: {genre}</p><p>Lead Actor: {leadActor}</p></>;
//   }
//   if (guesses.length >= 7) {
//     hint = <><p>Year: {movieYear}</p><p>Genre: {genre}</p> <p>Lead Actor: {leadActor}</p><p>Plot: {plot}</p></>;
//   }

//   return (
//     <div className="App">
//       <h1>Guess the Movie</h1>
//       <DifficultySelector setDifficulty={setDifficulty} />
//       <MovieImage moviePoster={moviePoster} guesses={guesses.length} correct={correct} />
//       {hint}
//       <GuessInput handleGuess={handleGuess} />
//       <p>Your guesses: {guesses.join(', ')}</p>
//       <h2>Your Total Score: {score}</h2>
//       <button onClick={handleLogout}>Logout</button>
//     </div>
//   );
// };

// export default App;


// import React, { useState, useEffect } from 'react';
// import './App.css';
// import MovieImage from './MovieImage';
// import GuessInput from './GuessInput';
// import Auth from './Auth';
// import { auth, firestore } from './firebase';
// import { onAuthStateChanged, signOut } from 'firebase/auth';
// import { doc, getDoc, setDoc } from 'firebase/firestore';
// import getMovieOfTheDay from './services/movieService';
// import DifficultySelector from './components/DifficultySelector';
// import { getSeedFromDate } from './services/utils'; // Import the utility function

// const App = () => {
//   const [movieTitle, setMovieTitle] = useState('');
//   const [moviePoster, setMoviePoster] = useState('');
//   const [movieYear, setMovieYear] = useState('');
//   const [leadActor, setLeadActor] = useState('');
//   const [plot, setPlot] = useState('');
//   const [genre, setGenre] = useState('');
//   const [guesses, setGuesses] = useState([]);
//   const [correct, setCorrect] = useState(false);
//   const [user, setUser] = useState(null);
//   const [history, setHistory] = useState([]);
//   const [score, setScore] = useState(0);
//   const [difficulty, setDifficulty] = useState('easy');
//   const [completedDifficulties, setCompletedDifficulties] = useState({
//     easy: false,
//     medium: false,
//     hard: false
//   });
//   const [gameState, setGameState] = useState({
//     easy: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
//     medium: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
//     hard: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false }
//   });

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, user => {
//       setUser(user);
//       if (user) {
//         fetchUserData(user);
//       } else {
//         resetState();
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   useEffect(() => {
//     if (!user) return;
//     loadGameState(difficulty);
//   }, [difficulty, user]);

//   useEffect(() => {
//     if (!user) return;
//     const date = new Date();
//     const seed = getSeedFromDate(date);
//     loadMovieOfTheDay(difficulty, seed);
//   }, [difficulty, user]);

//   const resetState = () => {
//     setMovieTitle('');
//     setMoviePoster('');
//     setMovieYear('');
//     setLeadActor('');
//     setPlot('');
//     setGenre('');
//     setGuesses([]);
//     setCorrect(false);
//     setHistory([]);
//     setScore(0);
//     setDifficulty('easy');
//     setCompletedDifficulties({
//       easy: false,
//       medium: false,
//       hard: false
//     });
//     setGameState({
//       easy: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
//       medium: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
//       hard: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false }
//     });
//   };

//   const loadGameState = (difficulty) => {
//     const savedState = gameState[difficulty];
//     setMovieTitle(savedState.movieTitle);
//     setMoviePoster(savedState.moviePoster);
//     setMovieYear(savedState.movieYear);
//     setLeadActor(savedState.leadActor);
//     setPlot(savedState.plot);
//     setGenre(savedState.genre);
//     setGuesses(savedState.guesses);
//     setCorrect(savedState.correct);

//     // if (!savedState.movieTitle) {
//     //   resetGame();
//     // }
//   };

//   const loadMovieOfTheDay = (difficulty, seed) => {
//     const movie = getMovieOfTheDay(difficulty, seed);
//     setMovieTitle(movie.title);
//     setMovieYear(movie.year);
//     fetchMovieDetails(movie.title);
//     setGameState({
//       ...gameState,
//       [difficulty]: {
//         ...gameState[difficulty],
//         movieTitle: movie.title,
//         moviePoster: '',
//         movieYear: movie.year,
//         leadActor: '',
//         plot: '',
//         genre: '',
//         guesses: [],
//         correct: false,
//         completed: false
//       }
//     });
//   };

//   const fetchUserData = async (user) => {
//     const userDocRef = doc(firestore, 'users', user.uid);
//     const userDoc = await getDoc(userDocRef);
//     if (userDoc.exists()) {
//       const data = userDoc.data();
//       setHistory(data.history || []);
//       setScore(data.score || 0);
//       setCompletedDifficulties(data.completedDifficulties || {
//         easy: false,
//         medium: false,
//         hard: false
//       });
//       setGameState(data.gameState || {
//         easy: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
//         medium: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
//         hard: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false }
//       });
//     }
//   };

//   const fetchMovieDetails = async (title) => {
//     try {
//       const response = await fetch(`https://www.omdbapi.com/?t=${encodeURIComponent(title)}&apikey=315d74c3`);
//       const data = await response.json();
//       if (data.Poster) {
//         setMoviePoster(data.Poster);
//       } else {
//         console.error('No poster found for the movie');
//       }
//       setLeadActor(data.Actors);
//       setPlot(data.Plot);
//       setGenre(data.Genre);

//       setGameState({
//         ...gameState,
//         [difficulty]: {
//           ...gameState[difficulty],
//           moviePoster: data.Poster,
//           leadActor: data.Actors,
//           plot: data.Plot,
//           genre: data.Genre
//         }
//       });
//     } catch (error) {
//       console.error('Error fetching movie details:', error);
//     }
//   };

//   const handleGuess = (guess) => {
//     if (completedDifficulties[difficulty]) return; // Prevent guessing if completed

//     const normalizedGuess = normalizeString(guess);
//     const newGuesses = [...guesses, normalizedGuess];
//     setGuesses(newGuesses);

//     if (newGuesses.length >= 8) {
//       setCorrect(false);
//       updateScoreAndHistory(false);
//     } else if (normalizedGuess === normalizeString(movieTitle)) {
//       setCorrect(true);
//       updateScoreAndHistory(true);
//     }

//     setGameState({
//       ...gameState,
//       [difficulty]: {
//         ...gameState[difficulty],
//         guesses: newGuesses
//       }
//     });
//   };

//   const updateScoreAndHistory = async (isCorrect) => {
//     const newScore = isCorrect ? score + 1 : score;
//     const newHistory = [...history, { title: movieTitle, correct: isCorrect }];

//     setScore(newScore);
//     setHistory(newHistory);

//     setCompletedDifficulties({
//       ...completedDifficulties,
//       [difficulty]: true
//     });

//     const updatedGameState = {
//       ...gameState,
//       [difficulty]: {
//         ...gameState[difficulty],
//         correct: isCorrect,
//         completed: true
//       }
//     };
//     setGameState(updatedGameState);

//     if (user) {
//       const userDocRef = doc(firestore, 'users', user.uid);
//       await setDoc(userDocRef, {
//         score: newScore,
//         history: newHistory,
//         completedDifficulties: {
//           ...completedDifficulties,
//           [difficulty]: true
//         },
//         gameState: updatedGameState
//       }, { merge: true });
//     }
//   };

//   const handleLogout = async () => {
//     try {
//       await signOut(auth);
//       resetState(); // Clear state on logout
//     } catch (error) {
//       console.error('Error signing out:', error);
//     }
//   };

//   const normalizeString = (str) => {
//     return str.toLowerCase().replace(/[\s\W]+/g, '').replace(/^the/, '');
//   };

//   // const nextMovie = () => {
//   //   resetGame();
//   // };

//   if (!user) {
//     return <Auth setUser={setUser} />;
//   }

//   if (correct || completedDifficulties[difficulty]) {
//     return (
//       <div className="App">
//         <h1>Guess the Movie</h1>
//         <DifficultySelector setDifficulty={setDifficulty} />
//         <MovieImage moviePoster={moviePoster} guesses={guesses.length} correct={correct} />
//         <h2>{correct ? 'Congratulations! You guessed it right!' : 'This difficulty has already been completed. Come back Tomorrow'}</h2>
//         <p>The movie title was: {movieTitle}</p>
//         <p>Genre: {genre}</p>
//         <p>Lead Actor: {leadActor}</p>
//         <p>Plot: {plot}</p>
//         <p>Your guesses: {guesses.join(', ')}</p>
//         <h2>Your Total Score: {score}</h2>
//         {completedDifficulties[difficulty] && <p>You've completed this difficulty! Come back Tomorrow</p>}
//         <button onClick={handleLogout}>Logout</button>
//       </div>
//     );
//   }

//   if (guesses.length >= 8 && !completedDifficulties[difficulty]) {
//     return (
//       <div className="App">
//         <h1>Guess the Movie</h1>
//         <DifficultySelector setDifficulty={setDifficulty} />
//         <MovieImage moviePoster={moviePoster} guesses={guesses.length} correct={correct} />
//         <h2>Sorry, you've run out of guesses!</h2>
//         <p>The movie title was: {movieTitle}</p>
//         <p>Year: {movieYear}</p>
//         <p>Genre: {genre}</p>
//         <p>Lead Actor: {leadActor}</p>
//         <p>Plot: {plot}</p>
//         <p>Your guesses: {guesses.join(', ')}</p>
//         <h2>Your Total Score: {score}</h2>
//         {completedDifficulties[difficulty] && <p>You've completed this difficulty! Come back Tomorrow</p>}
//         <button onClick={handleLogout}>Logout</button>
//       </div>
//     );
//   }

//   let hint = null;
//   if (guesses.length >= 2) {
//     hint = <p>Year: {movieYear}</p>;
//   }
//   if (guesses.length >= 4) {
//     hint = <><p>Year: {movieYear}</p><p>Genre: {genre}</p></>;
//   }
//   if (guesses.length >= 6) {
//     hint = <><p>Year: {movieYear}</p><p>Genre: {genre}</p><p>Lead Actor: {leadActor}</p></>;
//   }
//   if (guesses.length >= 7) {
//     hint = <><p>Year: {movieYear}</p><p>Genre: {genre}</p> <p>Lead Actor: {leadActor}</p><p>Plot: {plot}</p></>;
//   }

//   return (
//     <div className="App">
//       <h1>Guess the Movie</h1>
//       <DifficultySelector setDifficulty={setDifficulty} />
//       <MovieImage moviePoster={moviePoster} guesses={guesses.length} correct={correct} />
//       {hint}
//       <GuessInput handleGuess={handleGuess} />
//       <p>Your guesses: {guesses.join(', ')}</p>
//       <h2>Your Total Score: {score}</h2>
//       <button onClick={handleLogout}>Logout</button>
//     </div>
//   );
// };

// export default App;


//Working as of Thursday...App. Guesses do not reset the next day===========================================================================================

import React, { useState, useEffect } from 'react';
import './App.css';
import MovieImage from './MovieImage';
import GuessInput from './GuessInput';
import Auth from './Auth';
import { auth, firestore } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import getMovieOfTheDay from './services/movieService';
import DifficultySelector from './components/DifficultySelector';
import { getSeedFromDate } from './services/utils';

const App = () => {
  const [movieTitle, setMovieTitle] = useState('');
  const [moviePoster, setMoviePoster] = useState('');
  const [movieYear, setMovieYear] = useState('');
  const [leadActor, setLeadActor] = useState('');
  const [plot, setPlot] = useState('');
  const [genre, setGenre] = useState('');
  const [guesses, setGuesses] = useState([]);
  const [correct, setCorrect] = useState(false);
  const [user, setUser] = useState(null);
  const [history, setHistory] = useState([]);
  const [score, setScore] = useState(0);
  const [difficulty, setDifficulty] = useState('easy');
  const [completedDifficulties, setCompletedDifficulties] = useState({
    easy: false,
    medium: false,
    hard: false
  });
  const [gameState, setGameState] = useState({
    easy: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
    medium: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
    hard: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false }
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setUser(user);
      if (user) {
        fetchUserData(user);
      } else {
        resetState();
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!user) return;
    loadGameState(difficulty);
  }, [difficulty, user]);

  useEffect(() => {
    if (!user) return;
    const date = new Date();
    const seed = getSeedFromDate(date);
    loadMovieOfTheDay(difficulty, seed);
  }, [difficulty, user]);

  useEffect(() => {
    const checkResetDaily = () => {
      checkAndUpdateDailyReset();
    };

    checkResetDaily(); // Initial check when the component mounts

    const interval = setInterval(checkResetDaily, 1000 * 60 * 60 * 24); // Check once a day
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const resetState = () => {
    setMovieTitle('');
    setMoviePoster('');
    setMovieYear('');
    setLeadActor('');
    setPlot('');
    setGenre('');
    setGuesses([]);
    setCorrect(false);
    setHistory([]);
    // setScore(0);
    setDifficulty('easy');
    setCompletedDifficulties({
      easy: false,
      medium: false,
      hard: false
    });
    setGameState({
      easy: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
      medium: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
      hard: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false }
    });

    // Fetch new movie of the day for all difficulties
    const date = new Date();
    const seed = getSeedFromDate(date);
    ['easy', 'medium', 'hard'].forEach(diff => loadMovieOfTheDay(diff, seed));
  };

  const loadGameState = (difficulty) => {
    const savedState = gameState[difficulty];
    setMovieTitle(savedState.movieTitle);
    setMoviePoster(savedState.moviePoster);
    setMovieYear(savedState.movieYear);
    setLeadActor(savedState.leadActor);
    setPlot(savedState.plot);
    setGenre(savedState.genre);
    setGuesses(savedState.guesses);
    setCorrect(savedState.correct);
  };

  const loadMovieOfTheDay = async (difficulty, seed) => {
    const movie = getMovieOfTheDay(difficulty, seed);
    setMovieTitle(movie.title);
    setMovieYear(movie.year);
    fetchMovieDetails(movie.title);
    setGameState({
      ...gameState,
      [difficulty]: {
        ...gameState[difficulty],
        movieTitle: movie.title,
        moviePoster: '',
        movieYear: movie.year,
        leadActor: '',
        plot: '',
        genre: '',
        guesses: [],
        correct: false,
        completed: false
      }
    });
  
    // Update completed difficulties locally
    // setCompletedDifficulties({
    //   ...completedDifficulties,
    //   [difficulty]: false
    // });
  
    // // Update completed difficulties in Firestore
    // if (user) {
    //   const userDocRef = doc(firestore, 'users', user.uid);
    //   await setDoc(userDocRef, {
    //     completedDifficulties: {
    //       ...completedDifficulties,
    //       [difficulty]: false
    //     }
    //   }, { merge: true });
    // }
  };
  
  
  

  const fetchUserData = async (user) => {
    (async () => {
    const userDocRef = doc(firestore, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const data = userDoc.data();
      const currentDate = new Date().toDateString();
      if (data.lastPlayedDate !== currentDate) {
        resetState();
      }
      setHistory(data.history || []);
      setScore(data.score || 0);

       // Update completed difficulties based on lastPlayedDate
    const newCompletedDifficulties = {};
    const lastPlayedDate = new Date(data.lastPlayedDate);
    const currentDateString = currentDate;
    if (lastPlayedDate < currentDateString) {
      // Reset completed difficulties if lastPlayedDate is not the same as current date
      newCompletedDifficulties.easy = false;
      newCompletedDifficulties.medium = false;
      newCompletedDifficulties.hard = false;
    } else {
      // Otherwise, set completed difficulties based on database values
      newCompletedDifficulties.easy = data.completedDifficulties?.easy || false;
      newCompletedDifficulties.medium = data.completedDifficulties?.medium || false;
      newCompletedDifficulties.hard = data.completedDifficulties?.hard || false;
    }

      setCompletedDifficulties(data.completedDifficulties || {
        easy: false,
        medium: false,
        hard: false
      });
      setGameState(data.gameState || {
        easy: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
        medium: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false },
        hard: { movieTitle: '', moviePoster: '', movieYear: '', leadActor: '', plot: '', genre: '', guesses: [], correct: false, completed: false }
      });
      // if (data.lastPlayedDate !== currentDate) {
      //   await setDoc(userDocRef, { lastPlayedDate: currentDate }, { merge: true });
      // }
    }
  })();
  };

  const fetchMovieDetails = async (title, difficulty) => {
    try {
      const response = await fetch(`https://www.omdbapi.com/?t=${encodeURIComponent(title)}&apikey=315d74c3`);
      const data = await response.json();
      if (data.Poster) {
        setMoviePoster(data.Poster);
      } else {
        console.error('No poster found for the movie');
      }
      setLeadActor(data.Actors);
      setPlot(data.Plot);
      setGenre(data.Genre);

      setGameState({
        ...gameState,
        [difficulty]: {
          ...gameState[difficulty],
          moviePoster: data.Poster,
          leadActor: data.Actors,
          plot: data.Plot,
          genre: data.Genre
        }
      });
    } catch (error) {
      console.error('Error fetching movie details:', error);
    }
  };

  const handleGuess = (guess) => {
    if (completedDifficulties[difficulty]) return; // Prevent guessing if completed

    const normalizedGuess = normalizeString(guess);
    const newGuesses = [...guesses, normalizedGuess];
    // setGuesses(newGuesses);

    const isCorrectGuess = normalizedGuess === normalizeString(movieTitle);

    if (isCorrectGuess) {
      setCorrect(true);
      updateScoreAndHistory(true);
    } else if (newGuesses.length >= 8) {
      setCorrect(false);
      updateScoreAndHistory(false);
    } else {
      setGuesses(newGuesses);
      setGameState({
        ...gameState,
        [difficulty]: {
          ...gameState[difficulty],
          guesses: newGuesses
        }
      });
    }
  };

  // const updateScoreAndHistory = async (isCorrect) => {
  //   const newScore = isCorrect ? score + 1 : score;
  //   const newHistory = [...history, { title: movieTitle, correct: isCorrect }];

  //   setScore(newScore);
  //   setHistory(newHistory);

  //   setCompletedDifficulties({
  //     ...completedDifficulties,
  //     [difficulty]: true
  //   });

  //   const updatedGameState = {
  //     ...gameState,
  //     [difficulty]: {
  //       ...gameState[difficulty],
  //       correct: isCorrect,
  //       completed: true
  //     }
  //   };
  //   setGameState(updatedGameState);

  //   if (user) {
  //     const userDocRef = doc(firestore, 'users', user.uid);
  //     await setDoc(userDocRef, {
  //       score: newScore,
  //       history: newHistory,
  //       completedDifficulties: {
  //         ...completedDifficulties,
  //         [difficulty]: true
  //       },
  //       gameState: updatedGameState,
  //       lastPlayedDate: new Date().toDateString()
  //     }, { merge: true });
  //   }
  // };

  const updateScoreAndHistory = async (isCorrect) => {
    if (isCorrect) {
      const newScore = score + 1;
      const newHistory = [...history, { title: movieTitle, correct: isCorrect }];
  
      setScore(newScore);
      setHistory(newHistory);
  
      // Update score and history in Firestore
      if (user) {
        const userDocRef = doc(firestore, 'users', user.uid);
        await setDoc(userDocRef, {
          score: newScore,
          history: newHistory
        }, { merge: true });
      }
    }
  
    // Update completed difficulties
    setCompletedDifficulties({
      ...completedDifficulties,
      [difficulty]: true
    });
  
    // Update completed difficulties in Firestore
    if (user) {
      const userDocRef = doc(firestore, 'users', user.uid);
      await setDoc(userDocRef, {
        completedDifficulties: {
          ...completedDifficulties,
          [difficulty]: true
        }
      }, { merge: true });
    }
  
    // Update game state
    const updatedGameState = {
      ...gameState,
      [difficulty]: {
        ...gameState[difficulty],
        correct: isCorrect,
        completed: true
      }
    };
    setGameState(updatedGameState);

    // Update last played date for the current difficulty
  if (user) {
    const currentDate = new Date().toDateString();
    const userDocRef = doc(firestore, 'users', user.uid);
    await setDoc(userDocRef, {
      [`gameState.${difficulty}.lastPlayedDate`]: currentDate
    }, { merge: true });
  }
};
  



  const handleLogout = async () => {
    try {
      await signOut(auth);
      resetState(); // Clear state on logout
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const normalizeString = (str) => {
    return str.toLowerCase().replace(/[\s\W]+/g, '').replace(/^the/, '');
  };

  const checkAndUpdateDailyReset = () => {
    const lastPlayedDate = localStorage.getItem('lastPlayedDate');
    const currentDate = new Date().toDateString(); // Use only the date string

    if (lastPlayedDate !== currentDate) {
      localStorage.setItem('lastPlayedDate', currentDate);
      resetState();
    
          // Reset completed difficulties in Firestore
    if (user) {
      const userDocRef = doc(firestore, 'users', user.uid);
      setDoc(userDocRef, {
        completedDifficulties: {
          easy: false,
          medium: false,
          hard: false
        }
      }, { merge: true });
    }
  }
  };

  if (!user) {
    return <Auth setUser={setUser} />;
  }

  if (correct || completedDifficulties[difficulty]) {
    return (
      <div className="App">
        <h1>Guess the Movie</h1>
        <DifficultySelector setDifficulty={setDifficulty} />
        <MovieImage moviePoster={moviePoster} guesses={guesses.length} correct={correct || completedDifficulties[difficulty]} />
        <h2>{correct ? 'Congratulations! You guessed it right!' : 'This difficulty has already been completed. Come back Tomorrow'}</h2>
        <p>The movie title was: {movieTitle}</p>
        <p>Genre: {genre}</p>
        <p>Lead Actor: {leadActor}</p>
        <p>Plot: {plot}</p>
        <p>Your guesses: {guesses.join(', ')}</p>
        <h2>Your Total Score: {score}</h2>
        {completedDifficulties[difficulty] && <p>You've completed this difficulty! Come back Tomorrow</p>}
        <button onClick={handleLogout}>Logout</button>
      </div>
    );
  }

  if (guesses.length >= 8 && !completedDifficulties[difficulty]) {
    return (
      <div className="App">
        <h1>Guess the Movie</h1>
        <DifficultySelector setDifficulty={setDifficulty} />
        <MovieImage moviePoster={moviePoster} guesses={guesses.length} correct={correct} />
        <h2>Sorry, you've run out of guesses!</h2>
        <p>The movie title was: {movieTitle}</p>
        <p>Year: {movieYear}</p>
        <p>Genre: {genre}</p>
        <p>Lead Actor: {leadActor}</p>
        <p>Plot: {plot}</p>
        <p>Your guesses: {guesses.join(', ')}</p>
        <h2>Your Total Score: {score}</h2>
        <button onClick={handleLogout}>Logout</button>
      </div>
    );
  }

  let hint = null;
  if (guesses.length >= 2) {
    hint = <p>Year: {movieYear}</p>;
  }
  if (guesses.length >= 4) {
    hint = <><p>Year: {movieYear}</p><p>Genre: {genre}</p></>;
  }
  if (guesses.length >= 6) {
    hint = <><p>Year: {movieYear}</p><p>Genre: {genre}</p><p>Lead Actor: {leadActor}</p></>;
  }
  if (guesses.length >= 7) {
    hint = <><p>Year: {movieYear}</p><p>Genre: {genre}</p> <p>Lead Actor: {leadActor}</p><p>Plot: {plot}</p></>;
  }

  return (
    <div className="App">
      <h1>Guess the Movie</h1>
      <DifficultySelector setDifficulty={setDifficulty} />
      <MovieImage moviePoster={moviePoster} guesses={guesses.length} correct={correct} />
      {hint}
      <GuessInput handleGuess={handleGuess} />
      <p>Your guesses: {guesses.join(', ')}</p>
      <h2>Your Total Score: {score}</h2>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default App;






