// import React, { useState } from 'react';
// import { auth } from './firebase';
// import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';

// const Auth = ({ setUser }) => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [isRegister, setIsRegister] = useState(false);

//   const handleAuth = async () => {
//     try {
//       if (isRegister) {
//         const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//         setUser(userCredential.user);
//       } else {
//         const userCredential = await signInWithEmailAndPassword(auth, email, password);
//         setUser(userCredential.user);
//       }
//     } catch (error) {
//       console.error("Authentication error:", error);
//     }
//   };

//   return (
//     <div>
//       <h2>{isRegister ? 'Register' : 'Login'}</h2>
//       <input 
//         type="email" 
//         placeholder="Email" 
//         value={email} 
//         onChange={(e) => setEmail(e.target.value)} 
//       />
//       <input 
//         type="password" 
//         placeholder="Password" 
//         value={password} 
//         onChange={(e) => setPassword(e.target.value)} 
//       />
//       <button onClick={handleAuth}>
//         {isRegister ? 'Register' : 'Login'}
//       </button>
//       <button onClick={() => setIsRegister(!isRegister)}>
//         {isRegister ? 'Switch to Login' : 'Switch to Register'}
//       </button>
//     </div>
//   );
// };

// export default Auth;

// added password reset and div names for styles / Also errors on pass and reg / password 2 for Reg/ Reg Email ============================================================================================================

import React, { useState } from 'react';
import { auth } from './firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signOut } from 'firebase/auth';
import './Auth.css'; // Import the CSS file

const Auth = ({ setUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [isRegister, setIsRegister] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [error, setError] = useState('');

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAuth = async () => {
    if (!isValidEmail(email)) {
      setError('Invalid email format.');
      return;
    }

    try {
      if (isRegister) {
        if (password !== confirmPassword) {
          setError('Passwords do not match.');
          return;
        }
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await sendEmailVerification(userCredential.user);
        setError('Registration successful! A verification email has been sent. Please verify your email before logging in.');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
      } else {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        if (!userCredential.user.emailVerified) {
          setError('Your email is not verified. Please check your inbox and verify your email before logging in.');
          await signOut(auth);  // Sign out the user if email is not verified
          return;
        }
        setUser(userCredential.user);
      }
    } catch (error) {
      console.error('Firebase error:', error);
      handleFirebaseErrors(error);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      alert('Password reset email sent!');
    } catch (error) {
      console.error('Password reset error:', error);
      setError('Error sending password reset email: ' + error.message);
    }
  };

  const handleFirebaseErrors = (error) => {
    switch (error.code) {
      case 'auth/email-already-in-use':
        setError('The email address is already in use by another account.');
        break;
      case 'auth/invalid-email':
        setError('Invalid email format.');
        break;
      case 'auth/user-not-found':
        setError('No user found with this email. Please register first.');
        break;
      case 'auth/wrong-password':
        setError('Incorrect password. Please try again.');
        break;
      case 'auth/too-many-requests':
        setError('Too many unsuccessful login attempts. Please try again later.');
        break;
      case 'auth/missing-email':
        setError('Email is required.');
        break;
      case 'auth/missing-password':
        setError('Password is required.');
        break;
      case 'auth/insufficient-permission':
        setError('Insufficient permissions to perform this action.');
        break;
      default:
        setError('Authentication error: ' + error.message);
        break;
    }
  };

  const closeError = () => {
    setError('');
  };

  return (
    <div className="auth-container">
      {error && (
        <div className="error-popup">
          <p>{error}</p>
          <button onClick={closeError}>Close</button>
        </div>
      )}
      <div className="auth-box">
        <h2>{isRegister ? 'Register' : 'Login'}</h2>
        {!resetPassword ? (
          <>
            <input 
              type="email" 
              placeholder="Email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
            />
            <input 
              type="password" 
              placeholder="Password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
            />
            {isRegister && (
              <input 
                type="password" 
                placeholder="Confirm Password" 
                value={confirmPassword} 
                onChange={(e) => setConfirmPassword(e.target.value)} 
              />
            )}
            <button onClick={handleAuth}>
              {isRegister ? 'Register' : 'Login'}
            </button>
            <button onClick={() => setIsRegister(!isRegister)}>
              {isRegister ? 'Switch to Login' : 'Switch to Register'}
            </button>
            <button onClick={() => setResetPassword(true)}>
              Forgot Password?
            </button>
          </>
        ) : (
          <>
            <input 
              type="email" 
              placeholder="Enter your email" 
              value={resetEmail} 
              onChange={(e) => setResetEmail(e.target.value)} 
            />
            <button onClick={handlePasswordReset}>
              Send Password Reset Email
            </button>
            <button onClick={() => setResetPassword(false)}>
              Back to Login
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Auth;




