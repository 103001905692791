import React, { useRef, useEffect } from 'react';
import './MovieImage.css';

const MovieImage = ({ moviePoster, guesses, correct }) => {
  const canvasRef = useRef(null);
  const imgRef = useRef(new Image());

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = imgRef.current;

    img.src = moviePoster;

    const maxPixelationLevel = 24; // Start with very high pixelation level
    const minPixelationLevel = 1; // Minimum pixelation level (completely clear)
    const pixelationLevel = correct || guesses >= 8 ? minPixelationLevel : Math.max(maxPixelationLevel - guesses * 2.5, minPixelationLevel);

    img.onload = () => {
      const width = img.width;
      const height = img.height;
      canvas.width = width;
      canvas.height = height;

      console.log(`Image loaded: ${width}x${height}`);
      console.log(`Pixelation level: ${pixelationLevel}`);

      if (correct) {
        ctx.drawImage(img, 0, 0, width, height);
      } else {
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCanvas.width = width / pixelationLevel;
        tempCanvas.height = height / pixelationLevel;

        // Draw the image on the temporary canvas
        tempCtx.drawImage(img, 0, 0, tempCanvas.width, tempCanvas.height);
        // Draw the scaled-down image back onto the main canvas
        ctx.drawImage(tempCanvas, 0, 0, tempCanvas.width, tempCanvas.height, 0, 0, width, height);
      }
    };

    img.onerror = (error) => {
      console.error('Image failed to load', error);
    };

  }, [moviePoster, guesses, correct]);

  return (
    <div className="movie-image-container">
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default MovieImage;
